/// <reference types="jest" />

import { inject, Injectable, NgZone } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { map, of } from 'rxjs';

import { leaveNgZone } from '@cosmos/util-common';
import {
  LazyLoadedScripts,
  LazyLoadingScriptService,
} from '@cosmos/util-lazy-load-script';
import { getZoneUnPatchedApi } from '@cosmos/zone-less';

@Injectable({ providedIn: 'root' })
export class ResizeObserverProvider {
  private readonly _ngZone = inject(NgZone);
  // eslint-disable-next-line no-restricted-globals
  private readonly _window = inject<typeof window>(WINDOW);

  private readonly _lazyLoadingScriptService = inject(LazyLoadingScriptService);

  provide() {
    const isJest = ngDevMode && typeof jest !== 'undefined';
    const local_ResizeObserver = isJest
      ? (jest.fn(() => ({
          observe: jest.fn(),
          unobserve: jest.fn(),
          disconnect: jest.fn(),
        })) as unknown as typeof globalThis.ResizeObserver)
      : getZoneUnPatchedApi('ResizeObserver');

    if (typeof local_ResizeObserver === 'function') {
      return of(local_ResizeObserver).pipe(leaveNgZone(this._ngZone));
    } else {
      return this._lazyLoadingScriptService
        .loadScript(LazyLoadedScripts.ResizeObserver)
        .pipe(map(() => this._window.ResizeObserver));
    }
  }
}
